



















import { Vue, Component, Prop } from "vue-property-decorator";
import { Auth, Locations } from "@/store/modules";

import firebase from 'firebase';
import { omit } from 'ramda';

@Component({
  name: 'AcceptInvite'
})
export default class Home extends Vue {
  code = '';
  error = '';
  isLoading = false;

  async mounted() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code as string;
    }

    this.isLoading = true;
    const result = await firebase.auth().getRedirectResult();
    if (!result || !result.user) {
      this.isLoading = false;
      this.$router.push({ path: this.$route.path, query: { ...omit(['redirect'], this.$route.query) }});
      return;
    }
    try {
      this.error = '';
      await Auth.doLogin({ idToken: await result.user.getIdToken(), code: this.code })
      await Locations.fetchAll();
    } catch (err) {
      this.error = err.message;
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, redirect: 'false' }});
    }
    this.isLoading = false;
  }

  async verify() {
    this.isLoading = true;
    try {
      this.error = '';
      const result = await Auth.verifyInviteCode({ code: this.code });
      if (result === false) {
        throw Error('The code you provided is invalid');
      }
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, code: this.code }});
      this.doLogin();
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async doLogin() {
    this.isLoading = true;
    try {
      this.error = '';
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, redirect: 'true' }});
      await firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider);
      // await Auth.doLogin({ idToken: await (signinresult.user as any).getIdToken() })
      // await Locations.fetchAll();
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }
}
